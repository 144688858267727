/* eslint-disable camelcase */
import {withZod} from '@remix-validated-form/with-zod';
import {z} from 'zod';

import {annualOnlineRevenueRangeValidation} from '@/components/shared/LeadFormFields/AnnualRevenueRangeSelect';

const baseSchema = {
  full_name: z.string().min(1, {message: 'plus:forms.contact.fullName.error'}),
  email: z
    .string()
    .min(1, {message: 'plus:forms.contact.email.error'})
    .email('plus:forms.contact.email.error'),
  phone: z.string().min(1, {message: 'plus:forms.contact.phone.error'}),
  company: z.string().min(1, {message: 'plus:forms.contact.company.error'}),
  website: z.string().min(1, {message: 'plus:forms.contact.website.error'}),
  country: z.string().min(1, {message: 'plus:forms.contact.country.error'}),
  ...annualOnlineRevenueRangeValidation,
  address: z.string().default(''),
};
const schema = {...baseSchema, is_agency: z.string()};
export const contactBlogFormValidator = withZod(z.object(baseSchema));
export const contactFormValidator = withZod(z.object(schema));

const {website, country, is_agency, ...base} = schema;
const referralSchema = {website, country, ...base};

const referralFormValidator = withZod(z.object(referralSchema));

export const getContactFormValidator = (formVariant?: string) => {
  switch (formVariant) {
    case 'referral':
      return referralFormValidator;
    default:
      return contactFormValidator;
  }
};
